import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'

import abordoBlancoImg from 'img/AllAbordoBlanco.svg'
// import trofeoImg from 'img/vectores/trofeo.svg'
import telRosa from 'img/vectores/tel-rosa.svg'
import mailRosa from 'img/vectores/mail-rosa.svg'

import fbRosa from 'img/vectores/fb-rosa.svg'
import twRosa from 'img/vectores/tw-rosa.svg'
import igRosa from 'img/vectores/ig-rosa.svg'

type NosotrosProps = PageProps
interface NosotrosState {
  mounted: boolean
}

class Nosotros extends Component<NosotrosProps, NosotrosState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state

    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <React.Fragment>
            <div className="container-fluid hero-nosotros">
              <div className="row">
                <div className="col-sm-12 text-center">
                  <h1 className="big-white">Acerca de Nosotros</h1>
                </div>
              </div>
            </div>
            <div className="container-fluid bienvenida-nos">
              <div className="row">
                <div className="col-sm-6 offset-sm-3 text-center">
                  <img src={abordoBlancoImg} className="img-responsive" />
                  <p>
                    Nace de la motivación por mejorar los servicios de transporte de pasajeros, ofreciendo una mejor alternativa en
                    comodidad, seguridad y confianza.
                  </p>
                </div>
              </div>
            </div>

            <div className="container-fluid quienes">
              <div className="row">
                <div className="col-sm-2 offset-sm-2 col-12" style={{ borderRight: '1px solid #ffffff' }}>
                  <br />
                  <br />
                  <h1 className="big-white">
                    Quienes
                    <br />
                    Somos
                  </h1>
                </div>
                <div className="col-sm-5 offset-sm-1 col-12">
                  <p>
                    Motivados por mejorar el servicio de transporte de pasajeros el Grupo Corporativo RH, en septiembre de 2017 constituye
                    la empresa Autotransportes y Carga All Abordo (ABORDO) para materializar un sueño concebido años antes y capitalizar
                    toda la experiencia de más 30 años en el transporte de mercancías, ofreciendo ahora el servicio de transporte de
                    pasajeros.
                  </p>
                </div>
              </div>
            </div>

            {/* <div className="container pad reconocimientos">
              <div className="row text-center">
                <div className="col-sm-4 col-6" style={{ borderTop: '1px solid transparent', borderLeft: '1px solid transparent' }}>
                  <img src={trofeoImg} className="img-responsive" />
                  <h3 className="big-blue">Lorem Ipsum</h3>
                  <p>El pasaje estándar Lorem Ipsum, usado desde el año 1500.</p>
                </div>
                <div className="col-sm-4 col-6" style={{ borderTop: '1px solid transparent' }}>
                  <img src={trofeoImg} className="img-responsive" />
                  <h3 className="big-blue">Lorem Ipsum</h3>
                  <p>El pasaje estándar Lorem Ipsum, usado desde el año 1500.</p>
                </div>
                <div className="col-sm-4 col-6" style={{ borderTop: '1px solid transparent', borderRight: '1px solid transparent' }}>
                  <img src={trofeoImg} className="img-responsive" />
                  <h3 className="big-blue">Lorem Ipsum</h3>
                  <p>El pasaje estándar Lorem Ipsum, usado desde el año 1500.</p>
                </div>
                <div className="col-sm-4 col-6" style={{ borderLeft: '1px solid transparent' }}>
                  <img src={trofeoImg} className="img-responsive" />
                  <h3 className="big-blue">Lorem Ipsum</h3>
                  <p>El pasaje estándar Lorem Ipsum, usado desde el año 1500.</p>
                </div>
                <div className="col-sm-4 col-6">
                  <img src={trofeoImg} className="img-responsive" />
                  <h3 className="big-blue">Lorem Ipsum</h3>
                  <p>El pasaje estándar Lorem Ipsum, usado desde el año 1500.</p>
                </div>
                <div className="col-sm-4 col-6" style={{ borderRight: '1px solid transparent' }}>
                  <img src={trofeoImg} className="img-responsive" />
                  <h3 className="big-blue">Lorem Ipsum</h3>
                  <p>El pasaje estándar Lorem Ipsum, usado desde el año 1500.</p>
                </div>
              </div>
            </div> */}

            <div className="container contacto-nos pad">
              <div className="row">
                <div className="col-sm-8" style={{ fontSize: 20 }}>
                  <ul>
                    <h4>Dirección</h4>
                    <li>Av. Zaragoza 240 Nte. Col. Centro</li>
                    <li>Los Mochis, Sinaloa.</li>
                    <li>C.P. 81200</li>
                    <br />
                    <li>
                      <img src={telRosa} /> (668) 688 6303
                    </li>
                    <li>
                      <img src={mailRosa} /> cotizaciones@ptxpaqueteria.com
                    </li>
                  </ul>
                </div>
                <div className="col-sm-4">
                  <ul>
                    <h4>Encuéntranos en:</h4>
                    <a href="https://www.facebook.com/viajaABORDO/" target="blank">
                      <li>
                        <img src={fbRosa} /> @viajaABORDO
                      </li>
                    </a>
                    <a href="https://twitter.com/AbordoAll" target="blank">
                      <li>
                        <img src={twRosa} /> @AbordoAll
                      </li>
                    </a>
                    <a href="https://www.instagram.com/viaja.abordo/" target="blank">
                      <li>
                        <img src={igRosa} /> @all.Abordo
                      </li>
                    </a>
                  </ul>
                </div>
              </div>
            </div>
          </React.Fragment>
        </AAnimate>
      </Layout>
    )
  }
}

export default Nosotros
